<template>
    <nav class="navbar bg-secondary">
        <ul class="navigation d-flex flex-wrap">
            <li class="nav-item order-1 order-md-2">
                <router-link :to="{name: 'All locations'}" class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" class="navIcon" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="20" height="23" viewBox="0 0 20 23">
                        <defs>
                            <clipPath id="clip-Checkpoints">
                                <rect width="20" height="23"/>
                            </clipPath>
                        </defs>
                        <g id="Checkpoints" clip-path="url(#clip-Checkpoints)">
                            <g id="Group_881" data-name="Group 881" transform="translate(-208.733 62.704)">
                                <g id="Path_1266" data-name="Path 1266">
                                    <path id="Path_1270" data-name="Path 1270"
                                          d="M220.591-50.165h-3.68L215.4-51.958v-2.555l1.53-1.719h3.648l1.5,1.727v2.539Zm-2.75-2h1.8l.425-.518v-1.076l-.41-.473h-1.836l-.428.48v1.062Z"
                                          fill="#fff"/>
                                </g>
                                <g id="Path_1265" data-name="Path 1265">
                                    <path id="Path_1271" data-name="Path 1271"
                                          d="M219.61-40.682l-1.432,0-8.162-8.423-.283-.7v-6.588l.285-.7,4.207-4.311.715-.3h7.594l.684.271,4.6,4.311.317.729v6.588l-.285.7Zm-7.877-9.527,7.165,7.394,7.236-7.4v-5.747L222.139-59.7h-6.778l-3.628,3.719Z"
                                          fill="#fff"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <label class="hidden-label">All locations</label>
                </router-link>
            </li>
            <li class="nav-item order-2 order-md-3">
                <router-link :to="{name: 'Chat messages'}" class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" class="navIcon" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="29" height="23" viewBox="0 0 29 23">
                        <defs>
                            <clipPath id="clip-Messages">
                                <rect width="29" height="23"/>
                            </clipPath>
                        </defs>
                        <g id="Messages" clip-path="url(#clip-Messages)">
                            <g id="Group_880" data-name="Group 880" transform="translate(-260.439 68.896)">
                                <g id="Path_1262" data-name="Path 1262">
                                    <path id="Path_1272" data-name="Path 1272"
                                          d="M281.78-47.172h-19.8l-.54-1.842,4.074-2.617.153-10.932.368-.761,6.559-5.347.632-.225h8.555l.683.269,5.72,5.347.317.731v9.6l-.359.767-5.72,4.777Zm-16.394-2h16.032l5.082-4.245v-8.7L281.386-66.9h-7.8l-5.922,4.827-.154,11-.46.827Z"
                                          fill="#fff"/>
                                </g>
                                <g id="Rectangle_643" data-name="Rectangle 643">
                                    <rect id="Rectangle_649" data-name="Rectangle 649" width="3" height="3"
                                          transform="translate(270.675 -58.953)" fill="#fff"/>
                                </g>
                                <g id="Rectangle_644" data-name="Rectangle 644">
                                    <rect id="Rectangle_650" data-name="Rectangle 650" width="3" height="3"
                                          transform="translate(275.675 -58.953)" fill="#fff"/>
                                </g>
                                <g id="Rectangle_645" data-name="Rectangle 645">
                                    <rect id="Rectangle_651" data-name="Rectangle 651" width="3" height="3"
                                          transform="translate(280.675 -58.953)" fill="#fff"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <label class="hidden-label">Chat</label>
                </router-link>
            </li>
            <li class="nav-item yellow order-3 order-md-1">
                <router-link class="nav-link" :to="{name: 'Home'}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="navIcon" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="25" height="23" viewBox="0 0 25 23">
                        <defs>
                            <clipPath id="clip-Maps">
                                <rect width="25" height="23"/>
                            </clipPath>
                        </defs>
                        <g id="Maps" clip-path="url(#clip-Maps)">
                            <path id="Path_1273" data-name="Path 1273"
                                  d="M324.2-68.5l-6.583,3.045-6.694-3.048h-.829L303-65.27l-.585.91v16.285l1.491.871,6.628-3.738,6.635,3.352.908,0,7-3.593.543-.889V-67.6ZM311.318-66.13l5.411,2.463v13.615l-5.411-2.734Zm-6.9,2.413,4.9-2.238v13.4l-4.9,2.766Zm19.2,11.031-4.89,2.51v-13.6l4.89-2.261Z"
                                  transform="translate(-301.414 69.508)" fill="#fff"/>
                        </g>
                    </svg>
                    <label class="hidden-label">Home map</label>
                </router-link>
            </li>
            <li class="nav-item order-4 order-md-4">
                <router-link class="nav-link" :to="{name: 'Checkpoints'}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="navIcon" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="25" height="23" viewBox="0 0 25 23">
                        <defs>
                            <clipPath id="clip-My_checkpoints">
                                <rect width="25" height="23"/>
                            </clipPath>
                        </defs>
                        <g id="My_checkpoints" data-name="My checkpoints" clip-path="url(#clip-My_checkpoints)">
                            <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                <path id="Path_1274" data-name="Path 1274"
                                      d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717ZM336.937-59.5l9.127,9.073,9.321-9.078v-3.83l-4.2-2.6-4.441,4.25-1.372.011L340.8-65.919l-3.863,2.566Z"
                                      fill="#fff"/>
                            </g>
                        </g>
                    </svg>
                    <label class="hidden-label">My checkpoints</label>
                </router-link>
            </li>
            <li class="nav-item order-5 order-md-5">
                <router-link class="nav-link" to="/settings">
                    <svg xmlns="http://www.w3.org/2000/svg" class="navIcon" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="7" height="23" viewBox="0 0 7 23">
                        <defs>
                            <clipPath id="clip-Menu">
                                <rect width="7" height="23"/>
                            </clipPath>
                        </defs>
                        <g id="Menu" clip-path="url(#clip-Menu)">
                            <g id="Group_879" data-name="Group 879" transform="translate(-317 -599)">
                                <rect id="Rectangle_646" data-name="Rectangle 646" width="3" height="3"
                                      transform="translate(322 603) rotate(90)" fill="#fff"/>
                                <rect id="Rectangle_647" data-name="Rectangle 647" width="3" height="3"
                                      transform="translate(322 609) rotate(90)" fill="#fff"/>
                                <rect id="Rectangle_648" data-name="Rectangle 648" width="3" height="3"
                                      transform="translate(322 615) rotate(90)" fill="#fff"/>
                            </g>
                        </g>
                    </svg>
                    <label class="hidden-label">Settings</label>
                </router-link>
            </li>
        </ul>
    </nav>
</template>
