<template>
<div>
    <div class="chatHeader">
        <a class="noLine" @click="backToInbox">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left" class="arrowIcon svg-inline--fa fa-long-arrow-alt-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
            </svg>
        </a>
        <div class="d-flex">
            <div class="imgWrapper">
                <img src="../assets/personPlaceholder.jpg" alt="Team member" />
            </div>
            <h3 class="text-center">Tour Guide</h3>
        </div>

        <a class="noLineMD" @click="backToInbox">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="xIcon svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
            </svg>
        </a>
    </div>
    <div class="mesgs">
        <div class="msg_history" id="msg_history">
            <div v-for="{body, author, dateCreated, index} in messages" :key="index">
                <div class="outgoing_msg" v-if="parseInt(author) === userID">
                    <div class="sent_msg">

                        {{body}}
                        <p class="time_date">{{format(dateCreated,'dd.MM.yyyy hh:mm')}}</p>
                    </div>
                </div>
                <div class="incoming_msg" v-else>
                    <div class="incoming_msg_img"> <img src="../assets/personPlaceholder.jpg" alt="placeholder"> </div>
                    <div class="received_msg">
                        <div class="received_withd_msg">

                            {{body}}
                            <p class="time_date">{{format(dateCreated,'dd.MM.yyyy hh:mm')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="type_msg">
            <div class="input_msg_write">
                <button class="imageButton">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="20" viewBox="0 0 22 20">
                        <defs>
                            <clipPath id="clip-Camera">
                                <rect width="22" height="20" />
                            </clipPath>
                        </defs>
                        <g id="Camera" clip-path="url(#clip-Camera)">
                            <g id="Group_891" data-name="Group 891" transform="translate(-13 -592)">
                                <path id="Subtraction_7" data-name="Subtraction 7" d="M2609.149-6673.832H2593v-12.911h3.836l1.982-2.258h4.625l1.836,2.258h3.869v12.91Zm-9.844-10.187-2.038,2.349v2.9l2.038,2.184h3.545l2.112-2.184v-2.9l-2.112-2.349H2599.3Z" transform="translate(-2577 7282.988)" fill="#ecbf09" />
                                <path id="Path_1278" data-name="Path 1278" d="M-2571.55,7287.971h2.059l1.227,1.363v1.684l-1.227,1.268h-2.059l-1.183-1.268v-1.684Z" transform="translate(2594.614 -6687.442)" fill="#ecbf09" />
                            </g>
                        </g>
                    </svg>

                </button>
                <button class="imageButton">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="20" viewBox="0 0 22 20">
                        <defs>
                            <clipPath id="clip-Gallery">
                                <rect width="22" height="20" />
                            </clipPath>
                        </defs>
                        <g id="Gallery" clip-path="url(#clip-Gallery)">
                            <path id="Path_1279" data-name="Path 1279" d="M21,21V3c-.022-.013-18.113-.049-18,0V21C2.864,21.021,20.984,20.868,21,21ZM8.5,13.5,11,16.51,14.5,12,19,18H5Z" transform="translate(-1 -2)" fill="#ecbf09" />
                        </g>
                    </svg>

                </button>
                <input type="text" class="write_msg" v-model="newMessage" placeholder="Say something..." />
                <button class="msg_send_btn" type="button" @click="sendMessage">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="20" viewBox="0 0 22 20">
                        <defs>
                            <clipPath id="clip-Send">
                                <rect width="22" height="20" />
                            </clipPath>
                        </defs>
                        <g id="Send" clip-path="url(#clip-Send)">
                            <path id="Path_1278" data-name="Path 1278" d="M2.008,16.763,18.057,9.882,2.008,3,2,8.352,13.469,9.882,2,11.411Z" transform="translate(1.471 0.118)" fill="#fff" />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import format from 'date-fns/format'
export default {
    props: {
        /*userID: {
            type: Number,
            required: true,
        },*/
        messages: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            userdata: null,
            newMessage: "",
            userID: null,
            format,
            scrollElement: "",
        }
    },
    mounted() {
        this.userID = this.$store.getters.getUser.id;
        this.scrollToBottom();
    },
    updated(){
        this.scrollToBottom();
    },
    methods: {
        backToInbox() {
            this.$emit("closeThisChat");
        },
        sendMessage() {
            this.$emit("sendMessage", this.newMessage);
            this.newMessage = "";
        },
        scrollToBottom() {
            let messageWindow = document.querySelector('#msg_history');
            let bottomOfWindow = messageWindow.scrollHeight;
            messageWindow.scrollTo({
                top: bottomOfWindow,
                behavior: 'smooth'
            });
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.chatHeader {
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    box-shadow: 3px 3px 6px #bebebe;
    display: flex;

    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }

    .noLine {
        color: $body-color;

        @media screen and (min-width: 768px) {
            display: none;
        }

        .arrowIcon {
            width: 14px;
            margin-right: 25px;
        }
    }

    .noLineMD {
        color: $body-color;
        visibility: hidden;

        @media screen and (min-width: 768px) {
            visibility: visible;
        }

        .xIcon {
            width: 14px;
            margin-right: 25px;
        }
    }

    .imgWrapper {
        img {
            -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            width: 41px;
            height: 41px;
            object-fit: cover;
            margin-top: -6px;
        }
    }

    h3 {
        margin-left: 20px;
        font-weight: 600;
        font-size: 20px;
        color: $body-color;
    }

}

.incoming_msg {
    margin-top: 26px;
}

.incoming_msg_img {
    display: inline-block;
    width: 10%;

    img {
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        width: 35px;
        height: 35px;
        object-fit: cover;
    }
}

.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 90%;
}

.received_withd_msg {
    background: #bebebe none repeat scroll 0 0;
    color: #fff;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
}

.time_date {
    color: #e8e8e8;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
    display: flex;
    justify-content: flex-end;
}

.received_withd_msg {
    width: 80%;
}

.mesgs {
    //float: left;
    padding: 30px 5px 0 5px;

    @media screen and (min-width: 768px) {
        height: calc(var(--vh, 1vh) * 100);
    }
}

.sent_msg {
    background: $secondary none repeat scroll 0 0;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 5px 10px 5px 12px;
    width: 100%;
}

.outgoing_msg {
    overflow: hidden;
    margin: 26px 10px 26px 0;
    display: flex;
    justify-content: flex-end;
}

.sent_msg {
    width: 81%;
}

.input_msg_write {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 6px;
    height: 48px;
}

.imageButton {
    background-color: transparent;
    border: none;
    outline: none;
    width: 25px;
}

.input_msg_write input {
    border: 1px solid $primary;
    color: $body-color;
    width: 55%;
    padding-left: 10px;
}

.msg_send_btn {
    background: $primary none repeat scroll 0 0;
    color: #fff;
    border: none;
    outline: none;
    height: 100%;
    width: 48px;
}

.messaging {
    padding: 0 0 15px 0;
}

.msg_history {
    height: calc(var(--vh, 1vh) * 76);
    overflow-y: auto;
    box-sizing:border-box;

    @media screen and (min-width: 768px) {
        height: calc(var(--vh, 1vh) * 84);
    }

    @media screen and (min-width: 1000px) {
        height: calc(var(--vh, 1vh) * 79);
    }

    @media screen and (min-height: 1250px) {
        height: 1200px;
    }

}
</style>
