<template>
<div class="screenHeight">
    <div class="chat pt-2">
        <div class="container">
            <div class="messaging">
                <div class="inbox_msg">
                    <div class="row">
                        <div class="col-0 col-md-5">
                            <inbox-chat-list @openThisChat="openThisChat" class="inboxList"></inbox-chat-list>
                        </div>
                        <div class="col-12 col-md-7">
                            <inbox-messages :messages='messages' @closeThisChat="closeThisChat" @sendMessage="sendMessage" class="chatMess"></inbox-messages>
                        </div>
                    </div>
                </div>
            </div>
            <mobile-navigation class="d-none d-md-block"></mobile-navigation>
        </div>
    </div>
</div>
</template>

<script>
import MobileNavigation from '@/components/MobileNavigation';
import InboxMessages from '@/components/InboxMessages';
import InboxChatList from '@/components/InboxChatList';
import axios from "axios"
import { CHAT_TOKEN } from '../config/variables';

const Chat = require('twilio-chat');

export default {
    data() {
        return {
            client: null,
            messages: [],
            userData: [],
            userID: null,
            response: [],
            chanel: null,
        }
    },
    beforeUnmount() {
        if (this.channel) {
            this.channel.off('messageAdded', this.addMessage);
        }
    },
    async mounted() {
        this.screenWidth = screen.width;
        window.addEventListener("resize", this.watchWidth);
        const {
            data
        } = await axios.get(CHAT_TOKEN);
        this.client = await Chat.Client.create(data);
        this.loadChannel();
    },
    methods: {
        async loadChannel() {
            this.messages = [];
            if (this.channel) {
                this.channel.off('messageAdded', this.addMessage);
            }
            this.userData = this.$store.getters.getUser;
            this.userID = this.userData.id;
            this.channel = await this.client.getChannelByUniqueName('channel_' + this.userID);

            this.channel.getMessages(20).then((messages) => {
                this.messages = messages.items;
            });
            this.channel.on('messageAdded', this.addMessage);
        },
        openThisChat() {
            if (window.innerWidth < 768){
                document.querySelector('.inboxList').style.display = 'none';
                document.querySelector('.chatMess').style.display = 'block';
            }
            else{
                document.querySelector('.inboxList').style.display = 'block';
                document.querySelector('.chatMess').style.display = 'block';
            }
        },
        closeThisChat() {
            if (window.innerWidth < 768){
                document.querySelector('.inboxList').style.display = 'block';
                document.querySelector('.chatMess').style.display = 'none';
            }
            else{
                document.querySelector('.inboxList').style.display = 'block';
                document.querySelector('.chatMess').style.display = 'none';
            }
        },
        sendMessage(newMessage) {
            this.channel.sendMessage(newMessage);
        },
        addMessage(msg) {
            this.messages.push(msg)
        },
    },
    components: {
        MobileNavigation,
        InboxMessages,
        InboxChatList
    },
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.container {
    max-width: 1170px;
    margin: auto;

    @media screen and (min-width: 1200px) {
        width: 100%;
        max-width: 100%;
    }

    .inboxList{
        display: block;
    }

    .chatMess{
        display: none;
    }

}
</style>
