<template>
<div class="mb-5 mb-md-none">
    <div class="inbox_people">
        <div class="headind_srch">
            <router-link to="/home" class="closeButton d-block d-md-none" data-bs-toggle="collapse" href="#search-field" aria-expanded="false" aria-controls="search-field">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left" class="svg-inline--fa fa-long-arrow-alt-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                    </path>
                </svg>
            </router-link>
        </div>
        <div class="inbox_chat">
            <div class="chat_list">
                <div class="chat_people">
                    <a href="#" @click="openChat">
                        <div class="row">
                            <div class="col-3">
                                <div class="chat_img"> <img src="../assets/personPlaceholder.jpg" alt="sunil" class="chatImage"> </div>
                            </div>
                            <div class="col-9">
                                <div class="chat_ib">
                                    <h4>Tour Guide</h4>
                                    <p>Contact for quesitons</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <mobile-navigation class="d-block d-md-none"></mobile-navigation>
</div>
</template>

<script>
import MobileNavigation from './MobileNavigation.vue';
export default {
    data() {
        return {}
    },
    methods: {
        openChat() {
            this.$emit("openThisChat");
        }
    },
    components: {
        MobileNavigation
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

img {
    max-width: 100%;
}

.inbox_people {
    overflow: hidden;

    @media screen and (min-width: 768px) {
        margin-left: 68px;
        border-right: 1px solid #c4c4c4;
    }
}

.inbox_msg {
    clear: both;
    overflow: hidden;
}

.top_spac {
    margin: 20px 0 0;
}

.recent_heading {
    width: 40%;
}

.srch_bar {
    display: flex;
    width: 100%;
    box-shadow: 3px 3px 6px #bebebe;
    height: 50px;
    position: relative;
    z-index: 100;

    #searchBar {
        border: none;
        width: 100%;
        padding: 2px 0 4px 6px;
    }
}

.closeButton {
    border: none;
    background-color: white;
    color: $grey-text-color;
    margin-top: 8px;
    height: 30px;
    z-index: 2;

    svg {
        height: 25px;
        margin-left: 10px;
    }
}

.headind_srch {
    overflow: hidden;
    padding: 0 3px 6px 3px;
}

.recent_heading h4 {
    color: #05728f;
    font-size: 21px;
    margin: auto;
}

.srch_bar .input-group-addon button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 0;
    color: #707070;
    font-size: 18px;
}

.srch_bar .input-group-addon {
    margin: 0 0 0 -27px;
}

.chat_ib h4 {
    font-size: 20px;
    color: $body-color;
    margin: 0 0 8px 0;
    font-weight: 600;
}

.chat_ib p {
    color: #bebebe;
    margin: auto
}

.chat_img {
    width: 100%;

    .chatImage {
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
}

.chat_people {
    overflow: hidden;
    clear: both;

    a {
        text-decoration: none;
    }
}

.chat_list {
    margin: 0;
    padding: 18px 16px 10px;
}

.inbox_chat {
    height: 550px;
    padding-bottom: 50px;

    @media screen and (min-width: 768px) {
        height: calc(var(--vh, 1vh) * 100);
        padding-bottom: 0px;
    }
}

.active_chat {
    background: #ebebeb;
}
</style>
